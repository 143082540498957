import { Link } from 'gatsby';
import React from 'react';
import Layout from '../../components/layout/layout';

import { ImagesAudiA6Kombi } from '../../Images';

const AudiA6Kombi: React.FC = () => {
    return (
        <Layout
            title="Audi A6 Kombi - WrapTown - profesjonalne oklejanie
        samochodów"
            desc="Zmiana koloru auta wykonana na Audi A6 w kombi, sprawdź sam jak wygląda nasza oferta oklejania aut."
            url="https://wraptown.pl/realizacje/audi-a6-kombi"
            type="article"
        >
            <main className="px-4 max-w-7xl mx-auto pt-20 pb-20 lg:px-6">
                <div className="mb-10">
                    <p>
                        <Link to="/">Home</Link> /{' '}
                        <Link to="/realizacje">Realizacje</Link> / Audi A6 Kombi
                    </p>
                </div>
                <div className="text-center">
                    <h3>Zmiana koloru auta</h3>
                    <h1 className="text-4xl  mb-6 font-extrabold text-black xl:text-5xl leading-tight xl:leading-snug">
                        Audi A6 Kombi - WrapTown
                    </h1>

                    <div className="grid grid-flow-row gap-4 sm:grid-cols-2 lg:grid-cols-3 lg:gap-8">
                        {ImagesAudiA6Kombi.map((image) => {
                            return (
                                <div>
                                    <img
                                        key={image.id}
                                        src={image.href}
                                        alt={image.alt}
                                    />
                                </div>
                            );
                        })}
                    </div>
                </div>
            </main>
        </Layout>
    );
};

export default AudiA6Kombi;
